<template>
  <div class="waiver-checkboxes-controller">
    <div class="waiver-checkboxes-controller__header">
      Checkboxes
      <Icon
        v-if="!isDisabled && checkboxes.length > 1"
        name="rectangle"
        is-clickable
        :color="isDragAndDropActive ? 'primary' : 'secondary-500'"
        @click="isDragAndDropActive = !isDragAndDropActive"
      />
    </div>
    <Draggable
      v-if="checkboxes.length"
      v-model="checkboxes"
      :disabled="!isDragAndDropActive"
      handle=".drag-handler"
      class="waiver-checkboxes-controller__fields"
    >
      <div
        v-for="(checkbox, index) in checkboxes"
        :key="index"
        class="waiver-checkboxes-controller__field"
      >
        <div
          v-if="!isDisabled && isDragAndDropActive"
          class="action drag-handler"
        >
          <Icon name="equal" color="secondary-500" />
        </div>
        <Input
          :value="getCheckboxPlainText(checkbox.text)"
          placeholder="Text of checkbox"
          isReadonly
          class="m-expand"
          :is-disabled="isDisabled"
        />
        <div class="action" v-if="!isDisabled && !isDragAndDropActive">
          <Icon
            name="pencil"
            color="secondary-500"
            is-clickable
            @click="editCheckbox(index)"
          />
        </div>
      </div>
    </Draggable>
    <IconButton v-if="!isDisabled" icon="plus" @click="addCheckbox">
      Add checkbox
    </IconButton>
    <WaiverCheckboxModal
      v-if="!isDisabled"
      :modal-name="modalName"
      :value="editedCheckbox"
      :fieldKeys="
        checkboxes
          .filter((_, index) => index !== editedIndex)
          .map((field) => field?.key)
      "
      @save="saveCheckbox"
      @close="resetEditedCheckbox"
      @remove="removeCheckbox"
    />
  </div>
</template>

<script>
import Draggable from "vuedraggable";
import { convert } from "html-to-text";
import WaiverCheckboxModal from "@/components/waivers/WaiverCheckboxModal";

export default {
  name: "WaiverCheckboxesController",
  components: { WaiverCheckboxModal, Draggable },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalName: "waiverCheckboxModal",
      editedIndex: null,
      isDragAndDropActive: false,
    };
  },
  computed: {
    checkboxes: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    editedCheckbox() {
      return this.editedIndex !== null ? this.checkboxes[this.editedIndex] : {};
    },
  },
  methods: {
    getCheckboxPlainText(text) {
      return convert(text, {
        wordwrap: false,
        selectors: [{ selector: "a", options: { ignoreHref: true } }],
      });
    },
    editCheckbox(index) {
      this.editedIndex = index;
      this.$modal.show(this.modalName);
    },
    saveCheckbox(newCheckboxValue) {
      const copy = [...this.checkboxes];
      copy[this.editedIndex] = newCheckboxValue;
      this.checkboxes = copy;
      this.closeModal();
    },
    removeCheckbox() {
      const copy = [...this.checkboxes];
      copy.splice(this.editedIndex, 1);
      this.checkboxes = copy;
      this.closeModal();
    },
    addCheckbox() {
      this.checkboxes = [
        ...this.checkboxes,
        {
          text: `Checkbox ${this.checkboxes.length + 1}`,
          isRequired: false,
        },
      ];
    },
    resetEditedCheckbox() {
      this.editedIndex = null;
    },
    closeModal() {
      this.$modal.hide(this.modalName);
    },
  },
};
</script>

<style lang="scss" scoped>
.waiver-checkboxes-controller {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $secondary-500;
  }

  &__fields {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__field {
    display: flex;
    align-items: stretch;
    gap: 24px;

    .action {
      display: flex;
      align-items: center;

      &.drag-handler {
        cursor: grab;
      }
    }

    .m-expand {
      flex: 1;
    }
  }
}
</style>
