<template>
  <Form class="waiver-form" v-slot="{ invalid, changed }">
    <div>
      <div class="grid-2">
        <FormItem rules="required" v-slot="{ isError }">
          <Input
            v-model="form.title"
            label="Title"
            placeholder="Title"
            :isError="isError"
            :is-disabled="isDisabled"
          />
        </FormItem>
        <FormItem rules="required" v-slot="{ isError }">
          <Input
            v-model="form.intro"
            label="Intro"
            placeholder="Intro"
            :isError="isError"
            :is-disabled="isDisabled"
          />
        </FormItem>
        <FormItem
          :rules="{
            numeric: true,
            ...(!isNaN(form.cutOffHours)
              ? {
                  minValue: form.cutOffHours,
                }
              : {}),
          }"
          v-slot="{ isError }"
        >
          <Input
            v-model="form.startTimeHours"
            label="Hours before booking when signature becomes available"
            placeholder="Start time (hours)"
            :isError="isError"
            :is-disabled="isDisabled"
          />
        </FormItem>
        <FormItem
          :rules="{
            numeric: true,
            ...(!isNaN(form.startTimeHours)
              ? {
                  maxValue: form.startTimeHours,
                }
              : {}),
          }"
          v-slot="{ isError }"
        >
          <Input
            v-model="form.cutOffHours"
            label="Hours before booking when signature becomes unavailable"
            placeholder="Cutoff time (hours)"
            :isError="isError"
            :is-disabled="isDisabled"
          />
        </FormItem>
        <FormItem
          class="grid-2__full-item"
          rules="required"
          v-slot="{ isError }"
        >
          <RichTextEditor
            v-model="form.text"
            label="Text"
            placeholder="Text"
            :is-error="isError"
            :is-disabled="isDisabled"
          />
        </FormItem>
      </div>
      <div class="divider mt-40 mb-24" />
      <FormItem class="grid-2">
        <CustomFieldsController
          v-model="form.fields"
          :is-disabled="isDisabled"
          :defaultFields="defaultFields"
        />
      </FormItem>
      <div class="divider mt-40 mb-32" />
      <FormItem class="grid-2">
        <WaiverCheckboxesController
          v-model="form.checkboxes"
          :is-disabled="isDisabled"
        />
      </FormItem>
    </div>
    <div class="buttons">
      <Button
        v-if="!isDisabled"
        class="mt-auto"
        :is-block="['xs', 'sm'].includes($mq)"
        :isLoading="isSubmitting"
        :isDisabled="invalid || !(changed || isFieldsChanged)"
        @click="$emit('submit', form)"
      >
        {{ submitButtonText }}
      </Button>
      <Guard permission="waivers.delete" v-slot="{ isAvailable }">
        <Button
          v-if="isAvailable && hasDeleteButton"
          :is-block="['xs', 'sm'].includes($mq)"
          isOutlined
          variant="danger"
          :isDisabled="isSubmitting"
          :isLoading="isDeleting"
          @click="remove"
        >
          Delete Waiver
        </Button>
      </Guard>
    </div>
  </Form>
</template>

<script>
import { mapActions } from "vuex";
import dialog from "@/plugins/dialog";
import RichTextEditor from "@/components/common/richTextEditor/RichTextEditor";
import WaiverCheckboxesController from "@/components/waivers/WaiverCheckboxesController";
import CustomFieldsController from "@/components/common/customFields/CustomFieldsController";
import Guard from "@/components/common/Guard";
import { STANDARD_WAIVER_FIELDS_LABELS_MAP } from "@/helpers/const";

export default {
  name: "WaiverForm",
  components: {
    Guard,
    CustomFieldsController,
    WaiverCheckboxesController,
    RichTextEditor,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
    submitButtonText: {
      type: String,
      default: "Save",
    },
    hasDeleteButton: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        ...this.value,
      },
      defaultFields: STANDARD_WAIVER_FIELDS_LABELS_MAP,
      isDeleting: false,
      isFieldsChanged: false,
    };
  },
  watch: {
    "form.fields"() {
      this.isFieldsChanged = true;
    },
  },
  methods: {
    ...mapActions({
      deleteWaiver: "waivers/deleteWaiver",
    }),
    async remove() {
      const confirmed = await dialog.confirm({
        title: "Delete Waiver?",
        message: "All waiver data will be lost",
        okText: "Delete",
        cancelText: "Cancel",
      });
      if (confirmed) {
        try {
          this.isDeleting = true;
          await this.deleteWaiver(this.$route.params.id);
          await this.$router.push({
            name: "Waivers",
          });
        } finally {
          this.isDeleting = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.waiver-form {
  display: flex;
  flex-direction: column;
  gap: 64px;
  height: 100%;

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (min-width: $media-laptop) {
      display: flex;
      flex-direction: row;
      gap: 24px 20px;
    }
  }
}
</style>
