<template>
  <CustomModal
    :name="modalName"
    :classes="['waiver-checkbox-modal']"
    @before-close="$emit('close')"
  >
    <div class="waiver-checkbox-modal__close">
      <Icon
        name="close"
        is-clickable
        color="secondary-500"
        @click="$modal.hide(modalName)"
      />
    </div>
    <template v-slot:header>
      <span class="waiver-checkbox-modal__title"> Customize checkbox </span>
    </template>
    <Form class="waiver-checkbox-modal__form" v-slot="{ invalid, changed }">
      <div class="waiver-checkbox-modal__form__fields">
        <FormItem
          rules="required"
          v-slot="{ isError }"
          :customError="!isValidKey ? 'Enter unique field key' : null"
        >
          <Input
            v-model="innerValue.key"
            label="Key"
            placeholder="Insert key"
            :isError="isError"
          />
        </FormItem>

        <FormItem rules="required" v-slot="{ isError }">
          <RichTextEditor
            v-model="innerValue.text"
            label="Checkbox text"
            placeholder="Text of checkbox"
            :isError="isError"
            :has-size="false"
            :has-alignment="false"
            :has-color="false"
            :has-list="false"
            is-single-line
          />
        </FormItem>
        <FormItem>
          <Checkbox v-model="innerValue.isRequired">Required</Checkbox>
        </FormItem>
      </div>
      <div class="waiver-checkbox-modal__actions">
        <Button
          :is-block="['xs', 'sm'].includes($mq)"
          :isDisabled="invalid || !changed || !isValidKey"
          @click="$emit('save', innerValue)"
        >
          Save
        </Button>
        <Button
          variant="danger"
          :is-block="['xs', 'sm'].includes($mq)"
          isOutlined
          @click="$emit('remove')"
        >
          Delete checkbox
        </Button>
      </div>
    </Form>
  </CustomModal>
</template>

<script>
import RichTextEditor from "@/components/common/richTextEditor/RichTextEditor";
export default {
  name: "WaiverCheckboxModal",
  components: { RichTextEditor },
  props: {
    modalName: {
      type: String,
      default: "waiverCheckboxModal",
    },
    value: {
      type: Object,
      required: true,
    },
    fieldKeys: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      innerValue: {},
    };
  },
  computed: {
    isValidKey() {
      return !Object.values(this.fieldKeys).includes(this?.innerValue?.key);
    },
  },
  watch: {
    value(newVal) {
      this.innerValue = { ...newVal };
    },
  },
};
</script>

<style lang="scss">
.waiver-checkbox-modal {
  padding: 40px 16px;
  display: flex;
  flex-direction: column;

  @media (min-width: $media-laptop) {
    padding: 40px;
  }
}
</style>

<style lang="scss" scoped>
.waiver-checkbox-modal {
  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: $secondary-500;
    margin-bottom: 40px;

    &.standard {
      margin-bottom: 24px;
    }
  }

  &__tip {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    background-color: $secondary-200;
    color: $secondary-500;
    font-size: 12px;
    line-height: 20px;
    padding: 12px 16px;
    margin-bottom: 24px;
    border-radius: 8px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 48px;

    &__fields {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
</style>
